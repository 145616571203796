
.navbar {
    box-shadow: 1px 1px 5px black;
    opacity: .8;
}

/*Overriding with !important styles of bootstrap*/

.navbar-light .navbar-nav .nav-link {
    color: rgb(0,0,0)!important;
    text-align: right;
}
.navbar a.nav-link:hover{
    background-color: black!important;
    color: white!important;
}

.navbar a.visited {
    color: red!important;
} 
