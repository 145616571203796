.background-ubication{
    background-image: url(../img/background-home.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.wrapper-maps{
    text-align: -webkit-center;
}

.background-column {
    background-color: rgba(70, 70, 70, .7);
    color: whitesmoke;
}

.background-ubication h1{
    text-align: center;
    font-weight: bold;
}

.info-contact{
    text-align: center;
}

.background-ubication .col a{
    
    text-decoration: underline;

}

.background-ubication .col{
    text-align: left;
}

  /* mouse over link */
.col a:hover {
    color:rgb(47, 47, 255);
}

/*
.col-sm a{
    color: white;
    font-weight: bold;
    text-decoration: underline;
}
  
.col-sm a:hover {
    color:rgb(47, 47, 255);
}
*/