
.main-footer{
    color: white;
    background-color: rgb(50, 50, 50);
    padding-top: 3em;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.col a{
    color: white;
    text-decoration: underline;
}

.col-sm a{
    color: white;
    font-weight: bold;
    text-decoration: underline;
}

  /* mouse over link */
.col a:hover {
    color:rgb(47, 47, 255);
}
  
  /* mouse over link */
.col-sm a:hover {
    color:rgb(47, 47, 255);
}
  
  /* selected link */
.col-sm a:active {
    color: pink;
}

.fa-instagram{
    font-size: 30px;
}
.fa-facebook{
    font-size: 30px;
}
.fa-youtube{
    font-size: 30px;
}