@import url(https://fonts.googleapis.com/css?family=Tangerine);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.page-container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap{
  flex: 1 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar {
    box-shadow: 1px 1px 5px black;
    opacity: .8;
}

/*Overriding with !important styles of bootstrap*/

.navbar-light .navbar-nav .nav-link {
    color: rgb(0,0,0)!important;
    text-align: right;
}
.navbar a.nav-link:hover{
    background-color: black!important;
    color: white!important;
}

.navbar a.visited {
    color: red!important;
} 


.main-footer{
    color: white;
    background-color: rgb(50, 50, 50);
    padding-top: 3em;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.col a{
    color: white;
    text-decoration: underline;
}

.col-sm a{
    color: white;
    font-weight: bold;
    text-decoration: underline;
}

  /* mouse over link */
.col a:hover {
    color:rgb(47, 47, 255);
}
  
  /* mouse over link */
.col-sm a:hover {
    color:rgb(47, 47, 255);
}
  
  /* selected link */
.col-sm a:active {
    color: pink;
}

.fa-instagram{
    font-size: 30px;
}
.fa-facebook{
    font-size: 30px;
}
.fa-youtube{
    font-size: 30px;
}
.search-menu{
    padding-left: 120px;
    padding-top: 45px;
    padding-bottom: 10px;
}

@media only screen and (max-width:500px) {
    /* For mobile phones: */
    .search-menu {
      padding: 0;
      padding-left: 10px;
      padding-top: 45px;
      padding-bottom: 10px;
    }
  }
.background-products{
    background-image: url(/static/media/background-home.4a7d9007.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.background-home{
    background-image: url(/static/media/background-home.4a7d9007.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.player-wrapper {
    position: relative;
    padding-top: 26%; /* Player ratio: 100 / (1280 / 720) */
    display: flex;
    justify-content: center;
    background-color: black;
  }
  
  .react-player {
    position: absolute;
    top: 0;
  }

.banner{
    background: url(/static/media/banner-botellas.8c6f9282.jpg);
    float: left;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover; /* 100% de ancho*/
    /*background-attachment: fixed;  Imagen fija*/
    text-align: center;
    margin-bottom: 8px;
}
.banner h3{
    background: rgba(0,0,0,.5);
    color:white;
    /* font-family: 'Tangerine', serif; */
    text-shadow: 4px 4px 4px black;
    
    height: 100%;
    box-sizing: border-box;
    padding-top: 3%;
}

@media screen and (min-width: 769px) {
    .banner h3 {
      font-size: 62px;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 768px) {
    .banner h3 {
      font-size: 56px;
    }
  }

  @media screen and (max-width: 500px) {
    .banner h3 {
      font-size: 35px;
    }
    .player-wrapper{
      height: 200px;
    }
  }

@media screen and (min-width: 601px) {
    div#map {
        height: 480px;
        width: 580px;
    }
}
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 770px) {
    div#map {
        height: 300px;
        width: 650px;
    }
}

@media screen and (max-width: 470px) {
    div#map {
        height: 230px;
        width: 330px;
    }
}
.background-ubication{
    background-image: url(/static/media/background-home.4a7d9007.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.wrapper-maps{
    text-align: -webkit-center;
}

.background-column {
    background-color: rgba(70, 70, 70, .7);
    color: whitesmoke;
}

.background-ubication h1{
    text-align: center;
    font-weight: bold;
}

.info-contact{
    text-align: center;
}

.background-ubication .col a{
    
    text-decoration: underline;

}

.background-ubication .col{
    text-align: left;
}

  /* mouse over link */
.col a:hover {
    color:rgb(47, 47, 255);
}

/*
.col-sm a{
    color: white;
    font-weight: bold;
    text-decoration: underline;
}
  
.col-sm a:hover {
    color:rgb(47, 47, 255);
}
*/
