@media screen and (min-width: 601px) {
    div#map {
        height: 480px;
        width: 580px;
    }
}
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 770px) {
    div#map {
        height: 300px;
        width: 650px;
    }
}

@media screen and (max-width: 470px) {
    div#map {
        height: 230px;
        width: 330px;
    }
}